<template>
    <div class="w-full min-h-screen flex flex-col items-center justify-center text-center p-2 space-y-20"> 
        <div class="w-full flex flex-col space-y-5 max-w-md mx-auto">
            <h1 class="text-2xl">
                {{ $t('waitingPage.completed', $store.state.locale) }} {{ completionNumber }} / 4
            </h1>
            <!-- Steps -->
            <!-- Step 0 - Registration  -->
            <div class="flex items-center justify-start space-x-2 w-full">
                <div 
                    class="w-6 h-6 min-w-6 border rounded flex items-center justify-center"
                    :class="completionNumber >= 1 ? 'bg-light-green-500 border-light-green-500' : 'bg-white border-gray-500'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>
                </div>
                <span>{{ $t('waitingPage.steps.one', $store.state.locale) }}</span>
            </div>
            <!-- Step 1 - ZDHC Keys  -->
            <div class="flex items-center justify-between space-x-10 w-full">
                <div class="flex items-center justify-start space-x-2">
                    <div 
                        class="w-6 h-6 min-w-6 border rounded flex items-center justify-center"
                        :class="completionNumber >= 2 ? 'bg-light-green-500 border-light-green-500' : 'bg-white border-gray-500'">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>
                    </div>
                    <span>{{ $t('waitingPage.steps.two', $store.state.locale) }}</span>
                </div>
                <button
                    class="text-blue-500 hover:underline flex items-center justify-center p-1 text-sm"
                    @click.prevent="openKeyHelpModal">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-6 min-w-6 fill-current text-light-green-500"><path d="M11.29,15.29a1.58,1.58,0,0,0-.12.15.76.76,0,0,0-.09.18.64.64,0,0,0-.06.18,1.36,1.36,0,0,0,0,.2.84.84,0,0,0,.08.38.9.9,0,0,0,.54.54.94.94,0,0,0,.76,0,.9.9,0,0,0,.54-.54A1,1,0,0,0,13,16a1,1,0,0,0-.29-.71A1,1,0,0,0,11.29,15.29ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM12,7A3,3,0,0,0,9.4,8.5a1,1,0,1,0,1.73,1A1,1,0,0,1,12,9a1,1,0,0,1,0,2,1,1,0,0,0-1,1v1a1,1,0,0,0,2,0v-.18A3,3,0,0,0,12,7Z"/></svg>
                </button>
            </div>
            <!-- ZDHC KEYS FORM  -->
            <div v-if="completionNumber === 1" class="max-w-md mx-auto flex flex-col space-y-5 rounded shadow p-5">
                <!--<div class="mb-1 sm:mb-2 text-left">
                    <label for="auth_key" class="inline-block mb-1 uppercase">Auth Key</label>
                    <input id="auth_key" v-model="fields.auth_key" name="auth_key" placeholder="Auth Key" type="text" class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border rounded shadow-sm appearance-none focus:shadow-outline border-gray-300 focus:border-blue-500"/>
                    <span
                        v-if="$store.state.errors.errors.auth_key" 
                        class="block text-sm text-red-500 text-center mx-auto" >
                        {{ $store.state.errors.errors.auth_key[0] }}
                    </span>
                </div>-->
                <div class="mb-1 sm:mb-2 text-left">
                    <label for="user_key" class="inline-block mb-1 uppercase">User Keys</label>
                    <input id="user_key" v-model="fields.user_key" name="user_key" placeholder="User Key" type="text" class="flex-grow w-full h-12 px-4 mb-2 transition duration-200 bg-white border rounded shadow-sm appearance-none focus:shadow-outline border-gray-300 focus:border-blue-500"/>
                    <span
                        v-if="$store.state.errors.errors.user_key" 
                        class="block text-sm text-red-500 text-center mx-auto" >
                        {{ $store.state.errors.errors.user_key[0] }}
                    </span>
                </div>
                <div class="mt-4 mb-2 sm:mb-4 flex items-center justify-end">
                    <button 
                        class="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none"
                        :class="setZDHCKeysButtonClasses"
                        :title="$t('auth.signup.signinButton', $store.state.locale)"
                        v-html="setZDHCKeysButtonContent"
                        :disabled="zdhcKeysStatus !== 'void'"
                        @click.prevent="updateUserKeys">
                    </button>
                </div>
                <button
                    class="text-light-green-500 hover:underline flex items-center justify-center p-1"
                    @click.prevent="checkForUserKeys"
                    v-html="setCheckForKeysButtonContent"
                    :disabled="checkForKeysStatus !== 'void'">
                </button>
                <button
                    class="text-blue-500 hover:underline flex items-center justify-center p-1 text-sm"
                    @click.prevent="openKeyHelpModal">
                    {{ $t('waitingPage.needHelp', $store.state.locale) }}
                </button>
            </div>
            <!-- Step 2 - Subscription  -->
            <div class="flex items-center justify-start space-x-2 w-full">
                <div 
                    class="w-6 h-6 min-w-6 border rounded flex items-center justify-center"
                    :class="completionNumber >= 3 ? 'bg-light-green-500 border-light-green-500' : 'bg-white border-gray-500'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>
                </div>
                <span>{{ $t('waitingPage.steps.three', $store.state.locale) }}</span>
            </div>
            <div v-if="completionNumber === 2" class="w-full py-2 px-4 flex flex-col space-y-3">
                <h1 class="text-lg font-semibold">{{ $t('waitingPage.complete_subscription', $store.state.locale) }}</h1>
                <p class="text-sm text-gray-400 text-left">{{ $t('waitingPage.complete_subscription_link', $store.state.locale) }}</p>
                <div v-if="!stripeUrl" class="w-full flex items-center justify-center p-2">
                    <svg class="animate-spin w-7 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                </div>
                <a 
                    v-else
                    :href="stripeUrl" 
                    class="px-2 py-1 mx-auto block max-w-max text-light-green-500 border border-light-green-500 rounded hover:bg-light-green-500 hover:text-white duration-300">
                    {{ $t('waitingPage.complete_subscription_click', $store.state.locale) }}
                </a>
                
            </div>
            <!-- Step 4  -->
            <div class="flex items-center justify-start space-x-2 w-full">
                <div 
                    class="w-6 h-6 min-w-6 border rounded flex items-center justify-center"
                    :class="completionNumber >= 4 ? 'bg-light-green-500 border-light-green-500' : 'bg-white border-gray-500'">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>
                </div>
                <span>{{ $t('waitingPage.steps.four', $store.state.locale) }}</span>
            </div>
            <div v-if="generatingTokenStatus === 'loading'" class="w-full py-2 px-4 flex flex-col items-center justify-center space-y-3">
                <svg class="animate-spin w-5 text-blue-500 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                <span> Generating Token: please wait...</span>
            </div>
            <div v-else-if="generatingTokenStatus === 'error'" class="w-full py-2 px-4 flex flex-col items-center justify-center space-y-3">
                <span class="text-red-500">Something went wrong in generating your access token. Please contact our customer support </span>
                <a 
                    class="w-full text-left p-2 group duration-300 flex items-center justify-center"
                    :href="`mailto:${supportEmail}`">
                    <h2 class="text-lg font-semibold group-hover:underline">
                        Click here for help
                    </h2>
                </a>
            </div>
            <div v-if="completionNumber >= 4" class="flex items-center justify-center lg:justify-end">
                <router-link 
                    :title="$t('auth.signup.signinButton', $store.state.locale)"
                    :to="`/${$store.state.locale}/dashboard`"
                    class="w-full lg:w-auto lg:max-w-max bg-blue-500 hover:bg-blue-300 flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md focus:shadow-outline focus:outline-none">
                    {{ $t('auth.signup.signinButton', $store.state.locale) }}
                </router-link>
            </div>
        </div>
        <div class="flex flex-col space-y-5">
            <button
                :title="$t('sidebar.logout', $store.state.locale)"
                class="text-lg flex items-center justify-center"
                @click.prevent="signout"
                :class="setLogoutClasses"
                v-html="setLogoutContent"
                :disabled="logoutStatus !== 'void'">
            </button>
            <button 
                :title="$t('actions.help', $store.state.locale)"
                class="text-blue-500 hover:underline"
                @click.prevent="showHelpModal = true">
                {{ $t('actions.help', $store.state.locale) }}
            </button>
        </div>
        <teleport to="body">
            <!-- Help modal -->
            <div v-if="showHelpModal" class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto text-blue-500">
                <div class="flex flex-col w-full h-full items-center justify-center">
                    <div class="bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-700 rounded p-5 flex flex-col space-y-2 w-full md:w-2/3 lg:w-1/2 2xl:w-1/2 max-h-xl overflow-y-auto">
                        <div class="flex items-start justify-between">
                            <button 
                                title="Chiudi"
                                @click.prevent="closeHelpModal"
                                class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                        <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                            c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                            c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                                    </svg>
                            </button>
                        </div>
                        <h1 class="text-xl flex items-center space-x-1 dark:text-gray-200">
                            <span>{{ $t('actions.help', $store.state.locale) }}</span> 
                        </h1>
                        <div class="flex flex-col space-y-5">
                            <button 
                                class="w-full text-left flex flex-col space-y-2 border rounded p-2 group hover:bg-gray-100 duration-300"
                                @click.prevent="noKeysAssistanceRequest"
                                :disabled="noKeysAssistanceStatus !== 'void'">
                                <h2 class="text-lg font-semibold group-hover:underline">
                                    {{ $t('waitingPage.help.no_keys_title', $store.state.locale) }}
                                </h2>
                                <p  
                                    class=" text-left" 
                                    :class="setNoKeysAssistanceButtonClasses"
                                    v-html="setNoKeysAssistanceButtonContent"></p>
                            </button>
                            <a 
                                class="w-full text-left flex flex-col space-y-2 border rounded p-2 group hover:bg-gray-100 duration-300"
                                :href="`mailto:${supportEmail}`"
                                :disabled="noKeysAssistanceStatus !== 'void'">
                                <h2 class="text-lg font-semibold group-hover:underline">
                                    Altro
                                </h2>
                                <p class="text-sm text-gray-400 text-left">
                                    Per qualunque necessità, clicca qui per contattare il nostro supporto clienti!    
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="showHelpModal" class="fixed inset-0 bg-black bg-opacity-30 z-20 flex items-center justify-end"></div>
            <!-- Key Help modal -->
            <KeyHelpModal 
                v-if="showKeyHelpModal"
                @closeModal="closeKeyHelpModal"
            />
            <!--<div  class="fixed inset-0 p-6 bg-gray-1000 bg-opacity-90 z-30 overscroll-y-contain overflow-y-auto text-blue-500">
                <div class="flex flex-col w-full h-full items-center justify-center">
                    <div class="bg-white dark:bg-gray-900 dark:text-gray-400 border dark:border-gray-700 rounded p-5 flex flex-col space-y-2 w-full md:w-2/3 lg:w-1/2 2xl:w-1/2 max-h-xl overflow-y-auto">
                        <div class="flex items-start justify-between">
                            <button 
                                title="Chiudi"
                                @click.prevent="closeKeyHelpModal"
                                class="ml-auto bg-transparent min-w-min flex items-center justify-center transform hover:rotate-90 duration-300">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 64 64" class="w-4 fill-current text-gray-800 dark:text-gray-400">
                                        <path d="M34.5,32L62.2,4.2c0.7-0.7,0.7-1.8,0-2.5c-0.7-0.7-1.8-0.7-2.5,0L32,29.5L4.2,1.8c-0.7-0.7-1.8-0.7-2.5,0
                                            c-0.7,0.7-0.7,1.8,0,2.5L29.5,32L1.8,59.8c-0.7,0.7-0.7,1.8,0,2.5c0.3,0.3,0.8,0.5,1.2,0.5s0.9-0.2,1.2-0.5L32,34.5l27.7,27.8
                                            c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.9-0.2,1.2-0.5c0.7-0.7,0.7-1.8,0-2.5L34.5,32z"/>
                                    </svg>
                            </button>
                        </div>
                        <h1 class="text-xl flex flex-col text-left space-y-1 dark:text-gray-200">
                            <span>{{ $t('waitingPage.keyHelp.title', $store.state.locale) }}</span> 
                            <br>
                            <span class="text-sm">{{ $t('waitingPage.keyHelp.body', $store.state.locale) }}</span> 
                        </h1>
                        <div class="flex flex-col space-y-5">
                            <button 
                                class="w-full text-left flex flex-col space-y-2 border rounded p-2 group hover:bg-gray-100 duration-300"
                                @click.prevent="noKeysAssistanceRequest"
                                :disabled="noKeysAssistanceStatus !== 'void'">
                                <h2 class="text-lg font-semibold group-hover:underline">
                                    {{ $t('waitingPage.help.no_keys_title', $store.state.locale) }}
                                </h2>
                                <p  
                                    class=" text-left" 
                                    :class="setNoKeysAssistanceButtonClasses"
                                    v-html="setNoKeysAssistanceButtonContent"></p>
                            </button>
                            <a 
                                class="w-full text-left flex flex-col space-y-2 border rounded p-2 group hover:bg-gray-100 duration-300"
                                :href="`mailto:${supportEmail}`"
                                :disabled="noKeysAssistanceStatus !== 'void'">
                                <h2 class="text-lg font-semibold group-hover:underline">
                                    Altro
                                </h2>
                                <p class="text-sm text-gray-400 text-left">
                                    Per qualunque necessità, clicca qui per contattare il nostro supporto clienti!    
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>-->
            <div v-if="showKeyHelpModal" class="fixed inset-0 bg-black bg-opacity-30 z-20 flex items-center justify-end"></div>
        </teleport>
    </div>
</template>
<script>
import {useStore} from 'vuex';
import {computed, ref, reactive, defineAsyncComponent} from 'vue';
import {useI18n} from 'vue-i18n';
import {useRouter, useRoute} from 'vue-router';
import AuthService from '../../services/AuthService';
import ProfileService from '../../services/ProfileService';
import StripeService from '../../services/StripeService';
import ZdhcServiceV2 from '../../services/Zdhc/ZdhcServiceV2';
import ZdhcServiceV5 from '../../services/Zdhc/ZdhcServiceV5';
import SnackbarComposables from '../../composables/SnackbarComposables';
import CompanyService from '../../services/CompanyService';
import { exceptions_email } from '../../config/exceptions';  
//import moment from 'moment';
export default 
{
    components:
    {
        KeyHelpModal: defineAsyncComponent( () => import('../../components/modals/KeyHelpModal.vue'))
    },
    setup()
    {
        /**
         *
         * Data
         *
         */
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const fields = reactive({});
        const showHelpModal = ref(false);
        const showKeyHelpModal = ref(false);
        const generatingTokenStatus = ref('void');
        const { t, locale } = useI18n();
        const completionNumber = ref(0);
        const zdhcKeysStatus = ref('void');
        const logoutStatus = ref('void');
        const checkForKeysStatus = ref('void');
        const supportEmail = process.env.VUE_APP_YMPACT_SUPPORT_EMAIL;
        const noKeysAssistanceStatus = ref('void');
        const { logout } = AuthService();
        const { addSnackbar } = SnackbarComposables();
        const { sendZDHCKeysRequestEmail, updateSuscriptionExpiration } = ProfileService();
        const { userOrganizations, getSubscriptionStatus } = ZdhcServiceV2();
        const { inCheckAccessToken, inCheckSubscriptionStatus} = ZdhcServiceV5();
        const { expiresSpecificCheckoutSession, verifySubscription, createCheckout } = StripeService();
        const { updateKeys, checkForKeys } = process.env.VUE_APP_API_ENV === 'lumen' ? ProfileService() : CompanyService();
        const stripeSubscriptions = ref([]);
        // const selectedPriceId = ref(process.env.VUE_APP_DISCOUNT_PRICE);
        const selectedPriceId = ref(process.env.VUE_APP_ANNUAL_PRICE);
        const stripeUrl = ref(null);

        /* 
         *
         * Computed
         *
         */
        const setLogoutContent = computed( () => 
        {
            switch(logoutStatus.value)
            {
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                case 'void': default:
                    return t('sidebar.logout', locale.value);
            }
        });
        const setLogoutClasses = computed( () =>
        {
            switch(logoutStatus.value)
            {
                case 'void': case 'loading': default:
                    return 'text-light-green-500 hover:text-light-green-300';
                case 'error':
                    return `text-red-500 hover:text-red-700`;
                case 'success':
                    return `text-green-500 hover:text-green-700`;
            }
        });
        const setZDHCKeysButtonContent = computed( () => 
        {
            switch(zdhcKeysStatus.value)
            {
                case 'void':
                    return t('auth.signup.signinButton', store.state.locale);
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>';
                case 'error':
                    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-5 w-5 fill-current text-white"><path d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"/></svg>`;
                default:
                    return t('auth.signup.signinButton', store.state.locale);
            }
        });
        const setZDHCKeysButtonClasses = computed( () =>
        {
            switch(zdhcKeysStatus.value)
            {
                case 'void': case 'loading': default:
                    return 'bg-blue-500 hover:bg-blue-300';
                case 'error':
                    return `bg-red-500 hover:bg-red-700`;
                case 'success':
                    return `bg-green-500 hover:bg-green-700`;
            }
        });
        const setCheckForKeysButtonContent = computed( () =>
        {
            switch(checkForKeysStatus.value)
            {
                case 'loading':
                    return `<svg class="animate-spin h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'void': default:
                    return t('waitingPage.keys_already_inserted', store.state.locale);
            }
        });
        const setNoKeysAssistanceButtonContent = computed( () =>
        {
            switch(noKeysAssistanceStatus.value)
            {
                case 'loading':
                    return `<svg class="animate-spin h-9 w-9 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>`;
                case 'success':
                    return t('waitingPage.help.taking_charge_message', store.state.locale);
                case 'void': default:
                    return t('waitingPage.help.no_keys_message', store.state.locale);
            }
        });
        const setNoKeysAssistanceButtonClasses = computed( () =>
        {
            switch(noKeysAssistanceStatus.value)
            {
                case 'loading':
                    return `flex items-center justify-center`;
                case 'success':
                    return `text-base text-light-green-500 font-semibold`;
                case 'void': default:
                    return 'text-sm text-gray-400';
            }
        });

        /**
         *
         * Methods
         *
         */
        const init = async () => 
        {
            if(store.state.auth.user)
            {
                if(route.query.cancel && store.state.stripe.sessionId)
                {
                    await expiresSpecificCheckoutSession({ session: store.state.stripe.sessionId});
                }
                // registration phase completed
                completionNumber.value = 1;
                if(store.state.auth.user?.user_key)
                {
                    // Step 2 - subscription
                    completionNumber.value = 2;
                    // Check if user has an active subscription - if not, create new checkout
                    verifyUserSubscription();
                }
            }
           
        };
        const signout = () => 
        {   logoutStatus.value = 'loading';
            logout()
            .then( res => 
            {
                if(res === 200) return router.push(`/${store.state.locale}/signin`);
                else 
                {
                    logoutStatus.value = 'error';
                    setTimeout( () => logoutStatus.value = 'void', 2000);
                }
            });
        };
        const updateUserKeys = async() => 
        {
            zdhcKeysStatus.value = 'loading';
            if(!fields.user_key)
            {   
                zdhcKeysStatus.value = 'error';
                setTimeout( () => zdhcKeysStatus.value = 'void', 2000)
                return addSnackbar('error', t('insertKeys.no_keys', locale.value))
            }
            else if(fields.user_key.length !== 32)
            {
                zdhcKeysStatus.value = 'error';
                setTimeout( () => zdhcKeysStatus.value = 'void', 3000)
                return addSnackbar('error', t('insertKeys.invalid_user_key', locale.value))
            }
            // Check for user_key validation
            const keyRes = await inCheckAccessToken(fields.user_key);
            if(keyRes && keyRes.status === 'success') 
            {
                const res = await updateKeys({user_key: fields.user_key});
                if(res == 200) 
                {
                    completionNumber.value = 2;
                    //Check for subscription
                    verifyUserSubscription();
                } 
            }
            else 
            {
                zdhcKeysStatus.value = 'error';
                setTimeout( () => zdhcKeysStatus.value = 'void', 3000)
                return addSnackbar('error', t('insertKeys.invalid_user_key', locale.value))
            }
            /* */

        };
        const checkForUserKeys = async () => 
        {
            checkForKeysStatus.value = 'loading';
            const res = await checkForKeys();
            if(res === 200) 
            {
                completionNumber.value = 2; 
                verifyUserSubscription();
            }
            if(res) checkForKeysStatus.value = 'void';
        };
        const closeHelpModal =() => 
        {
            showHelpModal.value = false
        };
        const noKeysAssistanceRequest = async () => 
        {
            noKeysAssistanceStatus.value = 'loading';
            const res = await sendZDHCKeysRequestEmail();
            if(res === 200) setTimeout( () => noKeysAssistanceStatus.value = 'success', 3000);
            else setTimeout( () => noKeysAssistanceStatus.value = 'void', 3000);
        };
        const getAccessToken = () => 
        {
            generatingTokenStatus.value = 'loading';
            inCheckAccessToken(store.state.auth.user.user_key)
            .then( res => 
            {
                locale.value = store.state.locale;
                if(res && res.status === 'success') 
                {
                    userOrganizations()
                    .then( response => 
                    {  
                        if(response)
                        {
                            
                            store.commit('auth/SET_ORG_GUID', response.Organizations[0].OrgGUID);
                            store.commit('auth/SET_SUPPLIER_AID', response.Organizations[0].SupplierAID);
                            checkUserIncheckSubscriptionStatus();
                        }
                    });
                }
                else 
                {
                    addSnackbar('error', t('snackbar.refresh', store.state.locale)); // Add a more explicable message + logout 
                    return generatingTokenStatus.value = 'error';
                }
            });
        };
        const checkUserIncheckSubscriptionStatus = async () => 
        {
            let res;
            if(process.env.VUE_APP_ZDHC_VERSION == 2) res = await getSubscriptionStatus(store.state.auth.orgGUID);
            else res = await inCheckSubscriptionStatus(store.state.auth.orgGUID);
            if(res)
            {
                if(res.status === 'ok' && res.expirationDate)
                {
                    updateSuscriptionExpiration({subscription_expires_at: res.expirationDate});
                    completionNumber.value = 4;
                    generatingTokenStatus.value = 'void';
                }
                else 
                {
                    generatingTokenStatus.value = 'error';
                }
            }
            else 
                {
                    generatingTokenStatus.value = 'error';
                }
        }
        const verifyUserSubscription = async () => 
        {
            if(
                exceptions_email.includes(store.state.auth.user.email.toLowerCase())
                || store.state.auth.user.in_exception)
            {
                completionNumber.value = 3;
                getAccessToken();
            }
            else
            {
                setTimeout( async () => {
                    const res = await verifySubscription({price: selectedPriceId.value});
                    if(res.data.data.active.length)
                    { 
                        let activeSubscription = res.data.data.active[0];
                        store.commit('auth/SET_ACTIVE_SUBSCRIPTION', activeSubscription);
                        
                        // If user has an active subscription, go to step 3
                        completionNumber.value = 3;
                        getAccessToken();
                    }
                    else
                    {
                        createNewCheckout();
                    }
                }, 3000);
            }
        };
        const createNewCheckout = async () => 
        {
            const res = await createCheckout({price: selectedPriceId.value});
            if(res)
            { 
                stripeUrl.value = res.data.data.checkout.url;
                if(res.data?.data?.checkout?.id) store.commit('stripe/SET_SESSION_ID', res.data.data.checkout.id);
            }
        };
        const openKeyHelpModal = () => 
        {
            showKeyHelpModal.value = true;
            document.body.classList.add('overflow-hidden');
        };
        const closeKeyHelpModal = () => 
        {
            showKeyHelpModal.value = false;
            document.body.classList.remove('overflow-hidden');
        };
       

        /**
         *
         * Hooks
         *
         */
        locale.value = store.state.locale;
        init();


        return {
            /* Data */
            logoutStatus,
            completionNumber,
            fields,
            zdhcKeysStatus,
            checkForKeysStatus,
            showHelpModal,
            noKeysAssistanceStatus,
            supportEmail,
            stripeSubscriptions,
            stripeUrl,
            generatingTokenStatus,
            showKeyHelpModal,
            /* Computed */
            setLogoutContent,
            setLogoutClasses,
            setZDHCKeysButtonContent,
            setZDHCKeysButtonClasses,
            setCheckForKeysButtonContent,
            setNoKeysAssistanceButtonContent,
            setNoKeysAssistanceButtonClasses,
            /* Methods */
            checkUserIncheckSubscriptionStatus,
            signout,
            updateUserKeys,
            checkForUserKeys,
            closeHelpModal,
            noKeysAssistanceRequest,
            openKeyHelpModal,
            closeKeyHelpModal,
            updateKeys
        }
    }
}
</script>